import { handleErrorWithSentry } from '@sentry/sveltekit';
import * as Sentry from '@sentry/sveltekit';
import type { HandleClientError } from '@sveltejs/kit';

import { initSentry } from '$lib/sentry';

initSentry(Sentry);

if (window.Cypress) {
  // @ts-expect-error - Set on window so that Cypress can access it
  window.TEST_RUN_ID = process.env.VITE_TEST_RUN_ID;
}

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError: HandleClientError =
  import.meta.env.MODE === 'production'
    ? handleErrorWithSentry((errData) => {
        console.error({ errData });
      })
    : (errData) => {
        console.error({ errData });
      };
